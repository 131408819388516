import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick, plansPageView } from '@wix/bi-logger-membership/v2';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { CheckoutData, encodeBase64Url } from '@wix/pricing-plans-router-utils';
import { getSetupFeeAmount, hasSetupFee } from '@wix/pricing-plans-utils';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { QUERY_REQUEST_LIMIT, REQUEST_FILTER_LIMIT } from '../../constants';
import { TabState } from '../../constants/settings-events';
import { Analytics } from '../../services/analytics';
import { PlansApi } from '../../services/plans';
import { isUuid } from '../../services/uuid-compression';
import { WarmupData } from '../../services/WarmupData';
import { ModalType, noModal } from '../../types/common';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { resolveLocale } from '../../utils';
import { WIDGET_TYPE } from '../../utils/bi';
import { toError } from '../../utils/errors';
import { hasMultiplePages } from '../../utils/multiple-pages';
import { getSectionId } from '../../utils/navigation';
import { getOrderedVisiblePlans } from '../../utils/plan-visibility';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';
import { ListWidgetWithModalProps } from './Widget';

export class PlanListController {
  constructor(
    public setProps: (props: Partial<ListWidgetWithModalProps>) => void,
    protected plansApi: PlansApi,
    protected flowAPI: ControllerFlowAPI,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected appParams: ControllerParams['controllerConfig']['appParams'],
    protected analytics: Analytics,
    protected settings: SettingsReader,
    protected demoPlans: PublicPlan[],
    protected warmupData: WarmupData,
  ) {}

  public async initialize() {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.WidgetLoaded);
    this.flowAPI.panoramaClient?.transaction(PlanListInteractions.WidgetLoaded).start();

    const { plans } = await this.warmupData.cache('widget.plans', () => this.fetchAndOrderPlans());
    this.setProps({
      plans,
      tabState: TabState.REGULAR,
      selectPlan: this.onSelectPlan,
      locale: resolveLocale(this.wixCodeApi),
      modal: noModal,
    });

    if (this.flowAPI.environment.isViewer) {
      this.flowAPI.bi?.report(
        plansPageView({
          widgetType: WIDGET_TYPE.list,
        }),
      );
    }
    this.analytics.addProductImpression(plans);
  }

  fetchAndOrderPlans = async () => {
    const removePlanLimit = this.flowAPI.experiments.enabled(TPA_EXPERIMENTS.REMOVE_PLAN_LIMIT_TPA_WIDGET_SETTINGS);
    const visiblePlans = this.settings.uuidAsArray('visiblePlans').filter((id) => isUuid(id));
    let plans: PublicPlan[] = [];

    try {
      const exceedsFilterLimit = visiblePlans.length > REQUEST_FILTER_LIMIT;
      plans = removePlanLimit
        ? exceedsFilterLimit
          ? await this.plansApi.query({ limit: QUERY_REQUEST_LIMIT })
          : await this.plansApi.loadPaidPlans({ planIds: visiblePlans })
        : await this.plansApi.loadPaidPlans();
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }

    const useFixture = !plans?.length && this.wixCodeApi.window.viewMode === 'Editor';

    return { plans: getOrderedVisiblePlans(useFixture ? this.demoPlans : plans, this.settings) };
  };

  private onSelectPlan = async (plan: PublicPlan) => {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.NavigateToList);
    this.flowAPI.panoramaClient?.transaction(PlanListInteractions.NavigateToList).start();

    this.setProps({ selectedPlanId: plan.id });

    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({
        modal: { type: ModalType.PurchaseInPreview, onClose: this.closeModal },
        selectedPlanId: undefined,
      });

      this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
      this.flowAPI.panoramaClient?.transaction(PlanListInteractions.NavigateToList).finish();
    } else {
      this.navigateToCheckout(plan);
    }
  };

  private closeModal = () => {
    return this.setProps({ modal: noModal });
  };

  private navigateToCheckout = async (plan: PublicPlan) => {
    this.flowAPI.bi?.report(
      planPurchaseClick({
        planGuid: plan.id ?? '',
        widgetType: 'list',
        setupFeeAmount: hasSetupFee(plan) ? Math.round(Number(getSetupFeeAmount(plan)) * 100) : undefined,
      }),
    );
    this.analytics.clickProduct(plan);

    const checkoutData: CheckoutData = { planId: plan.id!, integrationData: {} };

    this.setCheckoutPagePath('/payment/' + encodeBase64Url(checkoutData));
  };

  private setCheckoutPagePath = async (path: string) => {
    const sectionId = getSectionId('checkout', {
      isMultiPageApp:
        this.flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_SPLIT_PAGES) &&
        (await hasMultiplePages(this.wixCodeApi)),
    });
    const { relativeUrl = '' } = await this.wixCodeApi.site.getSectionUrl({
      appDefinitionId: this.appParams.appDefinitionId,
      sectionId,
    });

    const currentUrl = '/' + this.wixCodeApi.location.path.join('/');
    const disableScrollToTop = currentUrl.startsWith(relativeUrl);
    this.wixCodeApi.location.to!(relativeUrl + path, { disableScrollToTop });
    this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
    this.flowAPI.panoramaClient?.transaction(PlanListInteractions.NavigateToList).finish();
  };
}
